/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import cn from 'classnames';
import { Button, Icon, Link, Text } from '@/components/atoms';
import styles from './cartModal.module.scss';
import useOutsideClick from '@/hooks/useOutsideClick';
import useFixBody from '@/hooks/useFixBody';
import parsePriceToNumber from '@/utils/parsePriceToNumber';
import Divider from '@/components/atoms/Divider';

// importar de forma dinamica CartItem
const CartItem = dynamic(() => import('@/components/molecules/CartItem'));

const CartModal = ({ close }) => {
  const cart = useSelector((state) => state.cart);
  const router = useRouter();
  const ref = useRef(null);
  const backRef = useRef(null);
  useEffect(() => {
    router.events.on('routeChangeComplete', close);
    return () => {
      router.events.off('routeChangeComplete', close);
    };
  }, [close]);
  const installments = (total) => {
    const string = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'ARS'
    }).format((parseFloat(parsePriceToNumber(total)) / 6).toFixed(2));
    return string.split(',')[0].replace('ARS', '');
  };

  useOutsideClick(ref, () => {
    if (cart.showModal) {
      close();
    }
  });

  useFixBody();

  const containerStyles = cn({
    [styles.container]: true,
    scrollBarStyles: true
  });

  const goToCheckout = async () => {
    router.push('/cart');
    close();
  };

  const cartTotal =
    (parsePriceToNumber(cart?.item_total) || 0) +
    (parsePriceToNumber(cart?.promo_total) || 0);
  const parse = (number) => {
    const result = new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'ARS'
    }).format(number.toFixed(2));
    return result.split(',')[0].replace('ARS', '');
  };

  return (
    <>
      <div
        ref={backRef}
        className={styles.back}
      />
      <div
        ref={ref}
        className={containerStyles}
      >
        <div className={styles.title}>
          <div style={{ display: 'flex' }}>
            <Text
              textStyle={{ fontSize: '20px', marginRight: '5px' }}
              textColor="var(--color-white-absolute)"
              weight="semibold"
            >
              Carrito
            </Text>
          </div>
          <button
            id="closeCartModal"
            onClick={() => close()}
            style={{
              background: 'transparent',
              border: 'none',
              cursor: 'pointer',
              width: '1rem'
            }}
            aria-label="close"
          >
            <Icon
              type="close"
              width="fit-content"
              fontSize={30}
              color="var(--color-white-absolute)"
            />
          </button>
        </div>
        <Divider color="var(--color-grey-lighter)" />
        {cart.line_items?.length > 0 ? (
          <>
            <div className={styles.items}>
              {cart.line_items.map((item) => (
                <CartItem
                  key={`${item.name} ${item.variant}`}
                  item={item}
                  close={close}
                />
              ))}
            </div>
            {cart.promo_total !== '0,00' && (
              <div
                className={styles.resumen}
                style={{ marginBottom: '5px' }}
              >
                <Text>Descuentos: $ {cart.promo_total?.split(',')[0]} </Text>
              </div>
            )}
            <div className={styles.resumen}>
              <Text
                textStyle={{ marginBottom: '.5rem' }}
                textColor="white"
                weight="semibold"
                textSize="l"
              >
                Total: <span>$ {parse(cartTotal)}</span>
              </Text>
              <Text
                textSize="xs"
                textColor="var(--color-primary)"
              >
                ó hasta 6 cuotas de ${installments(parse(cartTotal))} sin
                interés
              </Text>
            </div>
            <div style={{ marginBlock: '20px', marginTop: '70px' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                  justifyContent: 'center'
                }}
              >
                {cart?.for_free_shipping?.percentage < 100 ? (
                  <Text
                    textSize="s"
                    textColor="white"
                  >
                    Te faltan $
                    {cart?.for_free_shipping?.diference?.split(',')[0]} para
                    tener{' '}
                    <b style={{ color: 'var(--color-primary)' }}>
                      Envío Gratis a domicilio
                    </b>
                  </Text>
                ) : (
                  <Text
                    textSize="s"
                    textColor="white"
                  >
                    ¡Ya tenés envío gratis a domicilio!
                  </Text>
                )}
              </div>
              <div
                style={{
                  width: '100%',
                  height: '15px',
                  borderRadius: '15px',
                  backgroundColor: 'var(--color-grey-lighter)',
                  marginTop: '10px'
                }}
              >
                <div
                  style={{
                    backgroundColor: 'var(--color-primary)',
                    width: `${cart?.for_free_shipping?.percentage || 100}%`,
                    height: '100%',
                    borderRadius: '15px'
                  }}
                />
              </div>
            </div>
            <Button
              label="Iniciar compra"
              variant="primary"
              withOutPadding
              textWeight="semibold"
              style={{
                border: 'transparent',
                height: '40px',
                marginBottom: '20px',
                backgroundColor: 'var(--color-primary-alternative)'
              }}
              onClick={goToCheckout}
            />
          </>
        ) : (
          <div className={styles.empty}>
            <Text textStyle={{ textAlign: 'start' }}>
              ¡Añade productos al carrito para continuar con la compra!
            </Text>
            <button
              style={{
                background: 'transparent',
                border: 'none'
              }}
              onClick={close}
            >
              <Link
                primary
                href="/productos"
                linkStyle={{ marginTop: '10px' }}
              >
                <Text
                  textStyle={{ textDecoration: 'underline' }}
                  colored
                >
                  Continuá con tu compra
                </Text>
              </Link>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

CartModal.propTypes = {
  close: PropTypes.func.isRequired
};

export default CartModal;
